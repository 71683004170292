// UOPX THEME

// MATERIAL_UI
// ==============================

// HEADER
.topHeaderWrapper {
  background-color: $header-top-backg-color;
}
.topHeader {
  color: $color-white;
  a {
    color: $color-white;
    font-weight: 400;
    text-decoration: underline;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  @media (min-width: $min-xl) {
    max-width: 142rem;
  }
}
.callHeaderText {
  margin-right: 0.4rem;
}
.questionCall {
  .hdPhoneIcon {
    display: none;
  }
}
.bottomHeaderWrapper {
  margin: 0 auto;
  width: 100%;
}
.bottomHeader {
  margin: 0 auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  height: 5.8rem;
  @media (min-width: $min-lg) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 7.2rem;
  }
  @media (min-width: $min-xl) {
    max-width: 142rem;
  }
  .welcomeUser {
    display: block;
  }
  .topHeader {
    display: none;
  }
}
.dashboardMobileHDlogo, .dashboardDesktopHDlogo {
  display: none;
}
.welcomeUser {
  font-size: 1.2rem;
  @media (min-width: $min-lg) {
    font-size: 2rem;
  }
  .userName {
    line-height: 1.1;
    font-size: 1.6rem;
    @media (min-width: $min-lg) {
      display: inline;
      font-size: 2rem;
    }
  }
}
.headerAttachment {
  display: none;
}
.hdLogoIcon {
  padding-top: 0.4rem;
  height: auto;
  width: 19rem;
  @media (min-width: $min-lg) {
    padding-top: 0.7rem;
    width: 24rem;
  }
}
// header on other then new theme
&:not([data-theme="dashboardTheme"]) {
  #root > header {
    background: none!important;
    background-color: $color-white!important;
  }
}
// header on RedBuild template
&[data-theme="preDashboardTheme"] {
  #root > header {
    .hdLogoIcon {
      @media (min-width: $min-lg) {
        padding-top: 0!important;
      }
    }
  }
}

// Page
.mainContainer {
  margin-top: 7.4rem;
  padding: 1rem 2rem 0;
  min-height: calc(100vh - 44px - 35px);
  margin-bottom: 3rem;
  &#pgPersonalInfo {
    margin-top: 4rem;
    padding: 0;
    .gridOneWithBackground {
      padding: 5rem 4rem 2.5rem;
      @media (min-width: $min-lg) {
        padding: 5rem 2rem 2.5rem 3rem;
      }
      @media (min-width: 1200px) {
        padding: 5rem 5rem 2.5rem 6rem;
      }
    }
    .gridTwoWithStepper {
      padding: 3.5rem 0 2.5rem;
      @media (min-width: $min-lg) {
        margin-top: 0;
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
    h3.MuiTypography-h3 {
      font-size: 1.8rem;
      font-weight: 700;
      &.haveAnAccount {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
  &#pgLogout {
    margin-top: 4.4rem;
    padding: 0;
    main {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .wereHereToHelp {
      margin-top: 1rem;
      @media (min-width: $min-lg) {
        margin-bottom: 7rem;
      }
    }
  }
  @media (min-width: $min-md) {
    &#pgPersonalInfo {
      padding: 0;
    }
    &#pgLogout {
      margin-top: 8.4rem;
      padding: 0;
      main {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
  @media (min-width: $min-lg) {
    margin: 10rem auto 2rem;
    padding: 2rem 3rem;
    &#pgPersonalInfo {
      padding: 0;
    }
    &#pgLogout {
      margin-top: 13rem;
      padding: 0;
    }
  }
  @media (min-width: $min-xl) {
    width: 144rem;
  }
}
.chatHelp {
  svg path#Stroke-5 {
    fill: $icon-color!important;
    stroke: $icon-color!important;
  }
  svg path#Stroke-13 {
    fill: transparent;
    stroke-width: 4;
    stroke: $icon-color!important;
  }
  svg rect#Rectangle, svg rect#Rectangle-Copy {
    fill: $color-white!important;
  }
  svg rect#Rectangle-Copy-2 {
    fill: $icon-color!important;
  }
}
// transition
&[data-pg=transition] {
  .pageTopNav {
    display: none;
  }
}
#pgTransition {
  .bottomReturnBtn {
    display: none;
  }
  h1 {
    margin: 1rem 0 2rem;
  }
  .pgTitle {
    display: none;
  }
  .viewMySubmittedApp {
    .MuiBox-root {
      margin: 0;
    }
  }
  .taskActions {
    .MuiGrid-root:last-child {
      text-align: center;
      .check-the-box {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
        label.MuiFormControlLabel-root {
          align-items: center;
        }
        .MuiFormControlLabel-label {
          margin-top: 0;
        }
        .checkCheckboxSpinner {
          .spinnerWrapper {
            height: 4.9rem;
            margin-top: 0!important;
            padding-top: 1.35rem;
            margin-left: 0.6rem!important;
            padding-left: 0.1rem;
            margin-bottom: 0!important;
            @media (min-width: $min-lg) {
              height: 5.7rem;
              padding-top: 0.35rem;
              margin-left: 0.1rem!important;
            }
          }
          .loadingIcon {
            margin-top: 0!important;
            margin-bottom: 0!important;
            margin-left: 0.6rem!important;
          }
          .loadingTypography p {
            font-weight: 400;
            padding-left: 0.5rem;
            @media (min-width: $min-lg) {
              padding-left: 0.5rem;
            }
          }
          + .MuiFormControlLabel-label {
            display: none;
          }
        }
      }
    }
    @media (max-width: $max-md) {
      .MuiGrid-root:first-child {
        a.mainBtn {
          margin-bottom: 1rem;
        }
      }
    }
    .linkWithIcon {
      margin: 0 0 0.5rem!important;
      @media (min-width: $min-lg) {
        margin: -0.8rem 0!important;
      }
    }
    @media (min-width: $min-md) {
      // button
      .MuiGrid-root:first-child {
        text-align: center;
      }
    }
    @media (min-width: $min-lg) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .MuiGrid-root:first-child {
        margin: 0.9rem 0 1.2rem;
        .MuiButton-root.mainBtn {
          margin-right: 2rem;
        }
      }
      .MuiGrid-root:last-child {
        .check-the-box {
          padding-left: 0.5rem;
        }
      }
    }
  }
  .noCTA {
    .MuiGrid-root:last-child {
      .checkCheckboxSpinner {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
      }
    }
  }
}
// action items
#pgActionItems {
  .taskActions {
    .MuiGrid-root:last-child {
      text-align: center;
      .check-the-box {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
        label.MuiFormControlLabel-root {
          align-items: center;
        }
        .MuiFormControlLabel-label {
          margin-top: 0;
        }
        .checkCheckboxSpinner {
          .spinnerWrapper {
            height: 4.9rem;
            margin-top: 0!important;
            padding-top: 1.35rem;
            margin-left: 0.6rem!important;
            padding-left: 0.1rem;
            margin-bottom: 0!important;
            @media (min-width: $min-lg) {
              height: 5.7rem;
              padding-top: 0.35rem;
              margin-left: 0.1rem!important;
            }
          }
          .loadingIcon {
            margin-top: 0!important;
            margin-bottom: 0!important;
            margin-left: 0.6rem!important;
          }
          .loadingTypography p {
            font-weight: 400;
            padding-left: 0.5rem;
            @media (min-width: $min-lg) {
              padding-left: 0.5rem;
            }
          }
          + .MuiFormControlLabel-label {
            display: none;
          }
        }
      }
    }
    @media (max-width: $max-md) {
      .MuiGrid-root:first-child {
        a.mainBtn {
          margin-bottom: 1rem;
        }
      }
    }
    .linkWithIcon {
      margin: 0 0 0.5rem!important;
      @media (min-width: $min-lg) {
        margin: -0.8rem 0!important;
      }
    }
    @media (min-width: $min-md) {
      // button
      .MuiGrid-root:first-child {
        text-align: center;
      }
    }
    @media (min-width: $min-lg) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .MuiGrid-root:first-child {
        margin: 0.9rem 0 1.2rem;
        .MuiButton-root.mainBtn {
          margin-right: 2rem;
        }
      }
      .MuiGrid-root:last-child {
        .check-the-box {
          padding-left: 0.5rem;
        }
      }
    }
  }
  .noCTA {
    .MuiGrid-root:last-child {
      .checkCheckboxSpinner {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
      }
    }
  }
}
// StandAlonePage
&.stand-alone-page {
  a.logoutLink, .welcomeUser {
    display: none;
  }
}

// FOOTER
.MuiBreadcrumbs-ol li:nth-child(2) {
  // hidding the first separator as the first link is a hidden SEO
  display: none;
}

// Spotlight
.spotlight {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 3rem;
  &::after {
    content: "";
    display: block;
    margin: 1.3rem 0 -0.25rem;
    text-align: left;
    width: 4.3rem;
  }
  @media (min-width: $min-md) {
    font-size: 2.4rem;
  }
  &.mainUnderline {
    &::after {
      border-top: 0.3rem solid $spotlight-main;
    }
  }
  &.alternateUnderline {
    &::after {
      border-top: 0.3rem solid $spotlight-alternate;
    }
  }
}

// MENU
// ------------------------------
#simple-menu {
  ul.MuiList-root a .MuiListItemIcon-root {
    min-width: 3.5rem;
    padding-right: 1rem;
  }
}
// Navigation - Secondary menu
[id^="secondaryMenu"] {
  .MuiListItem-button {
    font-size: 1.2rem;
    @media (min-width: $min-lg) {
      font-size: 1.4rem;
    }
    @media (max-width: $max-md) {
      &:hover a {
        background-color: $mobile-menu-hover-backg-color;
      }
    }
  }
}

// TYPOGRAPHY
// ------------------------------
.MuiTypography-gutterBottom {
  margin-bottom: 2rem;
}
.MuiGrid-root > h1 {
  display: inline-block;
}
.MuiTypography-h1 {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  @media (min-width: $min-md) {
    font-size: 3.6rem;
  }
}
.MuiTypography-h2 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  line-height: inherit;
}
.MuiTypography-h3 {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
  line-height: inherit;
}
.MuiTypography-h6 {
  line-height: inherit;
}
.help-hint-text {
  color: $textHelpHintColor;
  font-weight: 400;
}
.fontIBMplexSerif {
  font-family: $font-ibm-plex-serif;
}
// HEADING
// ------------------------------
h1.MuiTypography-h1 {
  font-size: 2.4rem;
  font-weight: 700;
  @media (min-width: $min-md) {
    font-size: 3.2rem;
  }
}
h2.MuiTypography-h2.leadHdg {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2.4rem;
  @media (min-width: $min-md) {
    font-size: 2.4rem;
  }
}
h3.MuiTypography-h3.presentationHdg {
  font-size: 1.6rem;
  font-weight: 700;
  @media (min-width: $min-md) {
    font-size: 2rem;
  }
}
h3.MuiTypography-h3.formHdg {
  font-size: 1.6rem;
  font-weight: 500;
  @media (min-width: $min-md) {
    font-size: 1.6rem;
  }
}
h4.MuiTypography-h4.formHdg {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  @media (min-width: $min-md) {
    font-size: 1.6rem;
  }
}

// TABS
// ------------------------------
.MuiTabs-root {
  border-bottom: 1px solid $tabsBorderColor;
  color: $tabsTextColor;
  button[aria-selected="true"] {
    background-color: $tabsBackgroundColor;
    color: inherit;
    font-weight: 700;
  }
  button[aria-selected="false"] {
    background-color: $color-white;
    color: $tabsTextColor;
    color: inherit;
    font-weight: 400;
  }
  .MuiTabs-indicator {
    background-color: $tabsIndicatorColor;
  }
}
.newThemeTabs {
  .MuiTabs-root {
    border-bottom: 1px solid $accordion-tab-border-color;
    color: $tabsTextColor;
    button.MuiTab-root {
      text-transform: none;
      font-size: 1.4rem;
      opacity: unset;
      @media (min-width: $min-md) {
        font-size: 1.6rem;
      }
    }
    button[aria-selected="true"] {
      background-color: $color-white;
      .MuiTab-wrapper {
        color: $primary2-color;
        font-weight: 500;
      }
    }
    button[aria-selected="false"] {
      background-color: transparent;
      .MuiTab-wrapper {
        color: $textColor;
        font-weight: 500;
      }
    }
    .MuiTabs-indicator {
      background-color: $tabsIndicatorColor2;
    }
  }
  .MuiTabPanel-root {
    background-color: transparent;
  }
}
// bagde in tab
.tabBadge {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  background-color: $badgeBackgroundColor;
  color: $badgeColor;
  margin: 0 0.4rem;
}
.tabWithBadge {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.badgeLabel {
  flex: auto;
}
.withBadge {
  flex: none;
  width: 2.5rem;
}
button[aria-selected="true"] {
  .tabBadge {
    font-weight: 700;
  }
}
button[aria-selected="false"] {
  .tabBadge {
    font-weight: 400;
  }
}
// CYOP pg override
#pgFinanceChoosePath {
  .MuiTabs-root {
    button[aria-selected="true"] {
      color: $color-white; 
      background-color: $color-dark-river-blue;
    }
  }
}

// TABLE
// ------------------------------
table {
  border: 1px solid $tableBorderColor;
  .MuiTableHead-root {
    background-color: $darkBackground;
    .MuiTableCell-head {
      color: #fff;
      font-weight: $font-weight-md-bold;
      .MuiTableSortLabel-root:hover,
      .MuiTableSortLabel-root:focus,
      .MuiTableSortLabel-active,
      .MuiTableSortLabel-active:hover {
        color: #fff;
        .MuiTableSortLabel-icon {
          opacity: 1;
          color: #fff;
        }
      }
    }
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #fff;
  }
  .MuiTableRow-root {
    &:hover {
      background-color: transparent;
    }
    &.Mui-selected {
      background-color: transparent;
      &:hover {
        background-color:transparent;
      }
      td, td button {
        font-weight: $font-weight-bold;
      }
    }
    &.MuiTableRow-hover:hover,
    button:hover {
      background-color: transparent;
    }
    button {
      color: $textColor;
    }
  } 
}

// PAPER
// ------------------------------
.paper {
  border: 1px solid $ada-large-size-gray-on-white;
}

// PAGINATION
// ------------------------------
.MuiTablePagination-root {
  .MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
  }
  @media (min-width: $min-lg) {
    .MuiSvgIcon-root {
      width: 1em;
      height: 1em;
    }
  }
}

// DIALOG
// ------------------------------
.MuiDialog-root {
  z-index: 1301!important;
  &.ReviewAllForms {
    .MuiDialog-paper {
      @media (max-width: $max-sm) {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        margin: 0;
        border-radius: unset;
      }
    }
  }
  .MuiPaper-root {
    // Title
    > .MuiDialogTitle-root {
      padding: 2rem 1.4rem 0 2.4rem;
      @media (min-width: $min-md) {
        padding: 2rem 2rem 0 4rem;
        &#decision-letter-dialog-title {
          .smallCtaBtn {
            margin-right: 2rem;
          }
        }
      }
    }
    // Content
    > .MuiDialogContent-root {
      padding: 2rem 2.4rem 2.4rem;
      // iframe inside modal
      &.iframeWrapper {
        padding: 1rem 0 0;
      }
      @media (min-width: $min-md) {
        padding: 4rem 4rem 4.8rem;
      }
      &.tight {
        padding: 0.8rem 2.4rem;
        @media (min-width: $min-md) {
          padding: 0.8rem 4rem;
        }
      }
    }
    // Actions
    > .MuiDialogActions-root {
      padding: 0 2.4rem 2rem;
      @media (min-width: $min-md) {
        padding: 0 4rem 2rem;
      }
    }
  }
  // Modal main header - h2
  h2.MuiTypography-root {
    font-size: 2.2rem;
    font-weight: $font-weight-md-bold;
    margin: 1.5rem 0 2rem;
    @media (min-width: $min-md) {
      font-size: 3.2rem;
    }
  }
}
@media (max-width: $max-sm) {
  #DialogElement .block-msg-nobackground {
    padding-left: 2rem!important;
    &::before {
      left: -0.5rem;
    }
  }
}
// backdrop
.MuiBackdrop-root {
  background-color: $dialog-backdrop;
}

// Dialog (mobile)
.Dialog {
  @media (max-width: $max-sm) {
    .MuiPaper-root.MuiDialog-paper {
      max-width: 90%;
      height: 75%;
      max-height: 90%;
      border-radius: $dialog-border-radius;
    }
    &#DialogElement {
      .MuiPaper-root.MuiDialog-paper {
        height: auto;
        min-height: 44%;
        max-height: 70%;
      }
    }
  }
}

// PANEL
// ------------------------------
.MuiExpansionPanel-root {
  box-shadow: none;
  border: 0.1rem solid;
  border-color: #DADADA;
  width: 100%;
  margin-bottom: 1.2rem;
  &.Mui-expanded {
    margin-bottom: 1.2rem;
    margin-top: inherit;
    &:last-child {
      margin-bottom: 1.2rem;
    }
  }
  .MuiExpansionPanelSummary-root {
    background-color: #f7f7f7;
    min-height: 5.6rem;
    @media (max-width: $max-lg) {
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
    .MuiSvgIcon-root {
      width: 1.5em;
      height: 1.5em;
      @media (min-width: $min-md) {
        width: 1em;
        height: 1em;
      }
    }
  }
  .MuiExpansionPanelDetails-root {
    padding: 1.6rem 2.4rem 1.6rem;
  }
}

.MuiExpansionPanelSummary-content {
  &.Mui-expanded {
    margin: inherit;
  }
}

// Temporary stopgap mainly for local testing due to imported stylesheets from here https://bitbucket.org/codeuopx/next-form-apps/src/7dd904f681cfaa90d5507ff7989979dc21e30277/src/styles/App.scss?at=Master#lines-1370
.grants-link {
  font-size: 1.6rem !important;
}
.grants-link.fafsa-link {
  margin-top: 1.2rem;
}

// STEPPER
// ------------------------------
.MuiStepLabel-label {
  color: $textColor;
  font-size: 1.2rem;
  &.MuiButton-text, .MuiButton-label {
    font-size: 1.2rem;
    @media (min-width: $min-lg) {
      font-size: inherit;
    }
  }
  @media (min-width: $min-lg) {
    font-size: inherit;
  }
  &.MuiStepLabel-active {
    font-weight: 600;
  }
}
// Stepper (mobile stepper)
.MuiMobileStepper-root {
  padding: 0.8rem 0;
  .MuiButton-root {
    min-width: auto;
    .MuiSvgIcon-root {
      width: 1.5em;
      height: 1.5em;
    }
  }
}
.menuDropDown {
  .MuiPopover-paper {
    @media (max-width: $max-md) {
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
  .MuiList-root .MuiButtonBase-root {
    padding: 0;
    a {
      padding: 1.4rem 1.6rem;
      @media (min-width: $min-lg) {
        padding: 0.6rem 1.6rem;
      }
    }
  }
}
.main-nav {
  width: auto;
  .group-stepper-current svg text {
    fill: $color-white;
  }
  @media (max-width: $max-md) {
    margin-left: -2rem;
    margin-right: -2rem;
    .group-stepper-complete {
      .MuiButton-root .MuiButton-label > div {
        margin-right: 1rem;
      }
    }
    > .MuiStepper-horizontal {
      padding-top: 2rem;
      padding-bottom: 2rem;
      // Last step
      // moved border one level down in order to have a right padding
      > .MuiStep-horizontal:last-child {
        margin: 0;
        padding: 0 2rem 0 0;
        border: 0;
        &.group-stepper-incomplete {
          border: 0;
          > .MuiStepLabel-horizontal {
            padding: 0.65rem;
            margin: 0 0.5rem;
          }
        }
        &.group-stepper-current {
          > .MuiStepLabel-horizontal {
            padding: 0.65rem;
            margin: 0 0.5rem;
            border-color: $primary2-color;
            border-width: 1px;
            border-style: solid;
            border-radius: $btn-border-radius;
          }
        }
      }
    }
    .MuiStep-horizontal.group-stepper-incomplete {
      border: 0,
    }
    .MuiStep-horizontal {
      border-width: 1px;
      border-style: solid;
      border-radius: $btn-border-radius;
      padding: 0.65rem;
      margin: 0 0.5rem;
    }
    .MuiStep-horizontal.group-stepper-current {
      border-color: $primary2-color;
    }
    .MuiStep-horizontal.group-stepper-complete {
      border-color: $color-light-blue;
      background-color: $color-light-blue;
      box-shadow: 1px 2px 3px rgba(0,0,0,.1);
      &[data-active-btn="true"] {
        border-color: $mobile-menu-active-backg-color;
        background-color: $mobile-menu-active-backg-color;
      }
    }
  }
  @media (min-width: $min-lg) {
    border-bottom: 0;
    .MuiStepper-horizontal {
      padding: 1.4rem 2.4rem;
      border: 1px solid $color-almost-black-l70;
      border-radius: 4px;
    }
    .MuiStep-horizontal.group-stepper-complete {
      .MuiStepLabel-iconContainer .MuiSvgIcon-root {
        background-color: #30718D;
      }
    }
    .MuiStep-horizontal.group-stepper-current {
      .MuiStepLabel-iconContainer svg circle {
      fill: #30718D;
      }
    }
  }
  // main stepper connector lines width
  @media (max-width: $max-md) {
    .MuiStepConnector-horizontal {
      flex: 0 0 7%;
    }
  }
  @media (max-width: $max-sm) {
    .MuiStepConnector-horizontal {
      flex: 0 0 2%;
    }
  }
}
.main-nav-bottom {
  @media (max-width: $max-md) {
    margin: 0 0.5rem;
    border-bottom: 1px solid $color-lighter-gray;
  }
}
.sub-nav {
  width: auto;
  > .MuiStepper-horizontal {
    padding: 1.2rem;
    width: 89%;
    margin: 0 auto 2rem auto;
    > .MuiStep-horizontal:last-child {
      @media (max-width: $max-md) {
        padding-right: 6rem;
      }
    }
  }
  // sub stepper connector lines width
  @media (max-width: $max-sm) {
    .MuiStepConnector-horizontal {
      flex: 0 0 2%;
      justify-content: space-between;
    }
  }
  @media (min-width: $min-lg) {
    overflow-x: auto;
    .sub-group-stepper-complete {
      svg circle {
        color: #30718D;
      }
    }
    .sub-group-stepper-current {
      svg > svg {
        fill: #30718D;
      }
    }
  }
}
// mobile menu position
@media (max-width: $max-md) {
  #secondaryMenuPersonal,
  #secondaryMenuProgram,
  #secondaryMenuEducation,
  #secondaryMenuWork,
  #secondaryMenuFinancialPlan {
    .MuiMenu-paper {
      margin-top: 1.5rem;
      width: 120px;
      background-color: $color-blue;
    }
  }
}

// main
main {
  &.personalInfoContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

// personal info
.personalInfoContainer {
  .main-nav {
    padding-bottom: 1.25rem;
    > .MuiStepper-horizontal {
      @media (max-width: $max-md) {
        width: 280px;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    @media (max-width: $max-sm) {
      margin-top: -1rem;
    }
    @media (min-width: $min-lg) {
      margin: 3rem 0;
    }
  }
  .main-nav-bottom {
    margin: 0 0 2rem;
  }
}
.formPersonalInfo {
  @media (min-width: $min-lg) {
    padding-left: 5rem;
    padding-right: 12rem;
  }
  .emailSection,
  .legalNameSection,
  .mailingAddressSection,
  .phoneNumberSection,
  .accountCreationSection {
    padding: 0 1.5rem;
    @media (min-width: $min-lg) {
      padding: 0;
    }
  }
  #emailIntro {
    .mainBtn {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  .haveAnAccountSection {
    margin-bottom: 1.5rem;
  }
}

// BUTTON
// ------------------------------
.mainNavWrapper {
  .skipButton {
    display: block;
    margin-top: 2rem;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: $min-lg) {
    .skipButton {
      margin: 0 0 0 3rem;
      display: inline-block;
    }
  }
}
.MuiButton-root {
  text-transform: unset;
  font-size: 1.6rem;
  color: $textColor;
  .MuiButton-startIcon {
    margin-right: 0;
  }
  &.MuiButton-sizeSmall {
    font-size: 1.4rem;
  }
}
//.MuiButton-root,
.MuiButton-root.mainBtn {
  text-transform: inherit;
  background-color: transparent;
  color: $color-black;
  font-weight: $font-weight-medium;
  border-width: $btn-border-width;
  border-style: $btn-border-style;
  border-color: transparent;
  box-shadow: none;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.6rem 1.6rem;
  @media (min-width: $min-md) {
    margin-right: 1rem;
    width: auto;
    min-width: 24rem;
    max-width: 27.5rem;
    margin-bottom: 0;
    white-space: nowrap;
  }
  &.MuiButtonBase-root {
    border-radius: $btn-border-radius;
    &:hover, &:focus {
      box-shadow: none;
    }
  }
  &.Mui-focusVisible {
    box-shadow: none;
  }
  // SIZE large
  &.MuiButton-sizeLarge {
    padding: 0.6rem 2rem;
  }
  // SIZE small
  &.MuiButton-sizeSmall {
    padding: 0.3rem 1rem;
  }
  // Primary
  &.MuiButton-containedPrimary,
  &.MuiButton-textPrimary {
    background-color: $btn-primary-bg;
    color: $color-white;
    &:hover {
      background-color: $primary-hover-color;
    }
    &:active {
      background-color: $primary-active-color;
    }
    &.Mui-disabled,
    &[data-valid="false"],
    &[data-invalid="true"] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg-color;
      cursor: default;
      svg path {
        fill: $ada-large-size-gray-on-e6e9ea;
      }
    }
  }
  // Secondary
  &.MuiButton-outlinedSecondary,
  &.MuiButton-containedSecondary,
  &.MuiButton-textSecondary {
    border-color: $primary-color;
    border-width: $btn-border-width;
    color: $primary-color;
    .MuiSvgIcon-root {
      fill: $primary-color;
    }
    &:hover {
      border-color: $primary-hover-color;
      background-color: transparent;
      color: $primary-hover-color;
      border-width: $btn-border-width;
      .MuiSvgIcon-root {
        fill: $primary-hover-color;
      }
    }
    &:active {
      border-color: $primary-active-color;
      background-color: transparent;
      color: $primary-active-color;
    }
    &.Mui-disabled {
      color: $ada-large-size-gray-on-white;
      border-color: $color-almost-black-l70;
      svg path {
        fill: $ada-large-size-gray-on-e6e9ea;
      }
    }
  }
  // Icons
  .MuiButton-label {
    align-items: center;
    .MuiButton-endIcon {
      margin-left: 0.4rem;
    }
  }
  .MuiSvgIcon-root  {
    fill: $color-white;
    vertical-align: sub;
    margin-right: 0.4rem;
    &.circleIcon {
      fill: $color-phoenix-red;
    }
  }  
}
// new color (green)
.MuiButton-root.colorOverBtn {
  // Primary 2
  &.MuiButton-containedPrimary,
  &.MuiButton-textPrimary {
    background-color: $btn-primary2-bg;
    &:hover {
      background-color: $primary2-hover-color;
    }
    &:active {
      background-color: $primary2-active-color;
    }
  }
  // Secondary 2
  &.MuiButton-outlinedSecondary,
  &.MuiButton-containedSecondary,
  &.MuiButton-textSecondary {
    border-color: $primary2-color;
    color: $primary2-color;
    &:hover {
      border-color: $primary2-hover-color;
      color: $primary2-hover-color;
      .MuiSvgIcon-root  {
        fill: $primary2-hover-color;
      }
    }
    &:active {
      border-color: $primary2-active-color;
      color: $primary2-active-color;
    }
    .MuiSvgIcon-root  {
      fill: $primary2-color;
      vertical-align: sub;
      margin-right: 0.4rem;
    }  
  }
}
// Link (router)
a.linkForButton {
  display: inline-block;
  text-align: center;
  text-transform: inherit;
  background-color: transparent;
  color: $color-black;
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
  border-width: $btn-border-width;
  border-style: $btn-border-style;
  border-color: transparent;
  border-radius: $btn-border-radius;
  box-shadow: none;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.6rem 1.6rem;
  text-decoration: none;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  white-space: nowrap;
  @media (min-width: $min-md) {
    margin-right: 1rem;
    width: auto;
    min-width: 24rem;
    max-width: 27.5rem;
    margin-bottom: 0;
    white-space: nowrap;
    min-width: 24rem;
    max-width: 27.5rem;
  }
  // SIZE large
  &.linkLarge {
    padding: 0.775rem 2rem;
  }
  // SIZE small
  &.linkSmall {
    line-height: 1.65;
    padding: 0.375rem 1rem;
  }
  // Width small
  &.linkNarrow {
    min-width: 16rem;
  }
  // Primary
  &.linkPrimary {
    background-color: $btn-primary-bg;
    color: $color-white;
    &:hover {
      background-color: $primary-hover-color;
    }
    &:active {
      background-color: $primary-active-color;
    }
    &[disabled] {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg-color;
      cursor: default;
    }
  }
  // Secondary
  &.linkSecondary {
    border-color: $primary-color;
    border-width: $btn-border-width;
    color: $primary-color;
    &:hover {
      border-color: $primary-hover-color;
      background-color: transparent;
      color: $primary-hover-color;
      border-width: $btn-border-width;
    }
    &:active {
      border-color: $primary-active-color;
      background-color: transparent;
      color: $primary-active-color;
    }
    &[disabled] {
      color: $ada-large-size-gray-on-white;
      border-color: $color-almost-black-l70;
    }
  }
  // new color (green)
  &.colorOverBtn {
    // Primary 2
    &.linkPrimary {
      background-color: $btn-primary2-bg;
      &:hover {
        background-color: $primary2-hover-color;
      }
      &:active {
        background-color: $primary2-active-color;
      }
      &[disabled] {
        color: $btn-disabled-color;
        background-color: $btn-disabled-bg-color;
        cursor: default;
      }
    }
    // Secondary 2
    &.linkSecondary {
      border-color: $primary2-color;
      color: $primary2-color;
      &:hover {
        border-color: $primary2-hover-color;
        color: $primary2-hover-color;
      }
      &:active {
        border-color: $primary2-active-color;
        color: $primary2-active-color;
      }
      &[disabled] {
        color: $ada-large-size-gray-on-white;
        border-color: $color-almost-black-l70;
        cursor: default;
      }
    }
  }
}
// VARIANT text - Tertiary
a.MuiButton-text, button.pageBackBtn {
  color: $primary-color;
  font-weight: 400;
  &.backBtn .MuiSvgIcon-root {
    margin-right: 1rem;
  }
  &.backBtn,
  &.backBtn:hover,
  &.pageBackBtn,
  &.pageBackBtn:hover,
  &.mobileNav,
  &.mobileNav:hover {
    color: $textColor;
  }
  &:hover {
    color: $primary-hover-color;
    background-color: transparent;
  }
  &:active {
    color: $primary-active-color;
    background-color: transparent;
  }
  &.Mui-disabled,
  &.backBtn.Mui-disabled,
  &.pageBackBtn.Mui-disabled {
    color: $ada-normal-size-gray-on-e3e6e7;
    .MuiButton-label {
      color: $ada-large-size-gray-on-e6e9ea;
    }
    svg g {
      fill: $ada-large-size-gray-on-e6e9ea;
    }
  }
  // SIZE large
  &.MuiButton-textSizeLarge {
    padding: 0.6rem 0;
  }
  // SIZE small
  &.MuiButton-textSizeSmall {
    padding: 0.3rem 0;
  }
}
a.MuiButton-text {
  svg g {
    fill: #fff;
  }
}
a.MuiButton-text.pageBackBtn {
  svg g {
    fill: $linkColor;
  }
}
// button link
// add another link / remove link
.removeLink {
  border-bottom-color: $sectionSeparator;
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  max-width: 50rem;
  margin: 2rem 0 5rem;
}
.addLink {
  margin-top: -5rem;
}
button.addRemove {
  padding: 0.3rem 0.8rem;
  margin: 1.6rem 0;
  width: fit-content;
  .MuiButton-label {
    .MuiButton-startIcon {
      padding-right: 0.5rem;
    }
  }
  &:hover {
    background-color: transparent;
  }

}
.addAnotherSchool {
  margin-bottom: 2.5rem; // not definite - missing line
}
.removeSchool {
  margin-bottom: 1rem; // not set yet - missing line
}

// LINK
// ------------------------------
// link in a paragraph, sentence
.textLink {
  text-decoration: underline;
  cursor: pointer;
  color: $link-a;
  &:link {
    color: $link-a-link;
  }
  &:visited {
    color: $link-a-visited;
  }
  &:focus {
    color: $link-a-focus;
  }
  &:hover {
    color: $link-a-hover;
  }
  &:active {
    color: $link-a-active;
  }
}
// Router link needs this class to disable the click
.linkDisabled {
  pointer-events: none;
}
.openNewWindowIcon {
  margin-left: 0.4rem;
  vertical-align: text-bottom;
}
// SMALL CTA
.smallCtaBtn {
  padding: 0;
  margin-bottom: 0.6rem;
  margin-right: 3rem;
  line-height: 1.1;
  &.defaultLink {
    font-weight: 400;
    font-size: 1.6rem;
  }
  .MuiButton-label {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: $color-almost-black;
  }
  &:hover, &:focus {
    background-color: transparent;
    .MuiButton-label {
      color: $primary-color;
      border-bottom-color: $primary-color;
    }
  }
  &:active {
    background-color: transparent;
    .MuiButton-label {
      color: $color-phoenix-red-d40;
      border-bottom-color: $color-phoenix-red-d40;
    }
  }
}
a.smallCtaBtn {
  color: $textColor;
  text-decoration: none;
  margin-right: 0;
  &:hover, &:focus {
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }
  &:active {
    color: $color-phoenix-red-d40;
    text-decoration: none;
  }
  &:not(.MuiButton-text) {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: $color-almost-black;
    &:hover, &:focus {
      border-bottom-color: $primary-color;
    }
    &:active {
      border-bottom-color: $color-phoenix-red-d40;
    }
  }
  &.linkWithArrow:not(.MuiButton-text) {
    border-bottom-width: 0;
    &:hover, &:focus {
      color: $color-almost-black;
    }
    &:active {
      color: $color-almost-black;
    }
  }
}
// HTML button - and - HTML button and anchor with left icon
button:not(.MuiButtonBase-root.MuiButton-root).defaultCtaBtn {
  color: $textColor;
  text-decoration: none;
  margin-right: 0;
  &:hover, &:focus {
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }
  &:active {
    color: $color-phoenix-red-d40;
    text-decoration: none;
  }
  &:not(.MuiButton-text) {
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: $color-almost-black;
    &:hover, &:focus {
      border-bottom-color: $primary-color;
    }
    &:active {
      border-bottom-color: $color-phoenix-red-d40;
    }
  }
}
.linkWithIcon {
  justify-content: center;
  margin-top: 0;
  margin-bottom: 1.6rem;
  line-height: 1.25;
  svg {
    font-size: 2rem;
    margin-bottom: -0.5rem;
  }
  .defaultCtaBtn {
    font-size: 1.6rem;
    margin-right: 0.4rem!important;
  }
  .ctaLink {
    padding-top: 1rem;
    padding-right: 0.4rem;
  }
  button.defaultCtaBtn {
    border: 0;
    border-radius: 0;
    background: none;
    padding-inline: 0;
    padding-block: 0;
    font-family: $font-roboto;
  }
  @media (min-width: $min-lg) {
    justify-content: flex-start;
  }
}
.paragraphLink {
  width: unset;
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: 0;
  svg {
    fill: $link-secondary-color;
    height: 1.8rem;
    width: 1.8rem;
  }
}
// Icon CTA
.MuiGrid-align-items-xs-center[style="display: flex;"] .MuiButtonBase-root {
  padding-left: 0;
  &:hover, &:active, &:focus {
    box-shadow: none;
    background-color: transparent;
    text-decoration: underline;
  }
  .MuiSvgIcon-root {
    width: 0.75em;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}
// Arrow link
.linkArrow {
  &.link-primary {
    color: $link-primary-color;
    &:hover, &:focus {
      color: $link-primary-hover-color;
      & .rightArrow {
        &::before {
          border-color: $link-primary-hover-color;
        }
        &::after {
          border-color: $link-primary-hover-color;
        }
      }
    }
    &:active {
      color: $link-primary-active-color;
      & .rightArrow {
        &::before {
          border-color: $link-primary-active-color;
        }
        &::after {
          border-color: $link-primary-active-color;
        }
      }
    }
    & .rightArrow {
      &::before {
        border-color: $link-primary-color;
      }
      &::after {
        border-color: $link-primary-color;
      }
    }
  }
  &.link-secondary {
    color: $link-secondary-color;
    &:hover, &:focus {
      color: $link-secondary-hover-color;
      & .rightArrow {
        &::before {
          border-color: $link-secondary-hover-color;
        }
        &::after {
          border-color: $link-secondary-hover-color;
        }
      }
    }
    &:active {
      color: $link-secondary-active-color;
      & .rightArrow {
        &::before {
          border-color: $link-secondary-active-color;
        }
        &::after {
          border-color: $link-secondary-active-color;
        }
      }
    }
    & .rightArrow {
      &::before {
        border-color: $link-secondary-color;
      }
      &::after {
        border-color: $link-secondary-color;
      }
    }
  }
}

// LIST
// with Mui Typography and Mui List
.iconListWithHeader-MuiTypographyList {
  .MuiTypography-root {
    margin-bottom: 0;
  }
  .iconList-MuiList {
    padding-top: 0;
  }
}
// list with Mui ListItemIcon
.iconList-MuiList {
  padding-bottom: 2rem;
  .MuiListItemIcon-root {
    margin-top: 0.3rem;
    min-width: 3.5rem;
    svg {
      width: 2rem;
    }
  }
  .MuiListItem-root {
    align-items: flex-start;
  }
  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// FORM CONTROLS
// ------------------------------
.page-wrapper form {
  align-items: unset;
}
.MuiFormControl-root, .MuiAutocomplete-root {
  margin: 0 0 2.4rem 0;
  background-color: transparent;
}
.MuiAutocomplete-root > .MuiFormControl-root {
  margin: 0;
}

// ACCESSIBLE-AUTOCOMPLETE
.aaContainer {
  position: relative;
  margin-bottom: 2rem;
}
.aaContainer .adaAutocompleteCustomInput {
  position: absolute;
  width: 1px!important;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.autocomplete__wrapper {
	position: relative;
}
.adaAutocompleteOpenArrow,
.adaAutocompleteCloseArrow,
.adaAutocompleteSearch {
  position: absolute;
  right: 10px;
  top: 18px;
}
.adaAutocompleteCloseArrow--hide,
.adaAutocompleteOpenArrow--hide,
.adaAutocompleteSearch--hide {
  display: none;
}
.adaAutocompleteSpinner {
  position: absolute;
  right: 40px;
  top: 18px;
}
.adaAutocompleteErrorMsg {
  margin: 0;
  border-color: #b21f13;
  color: #b21f13!important;
  border-style: solid;
  border-width: 1px;
  width: 100%!important;
  border-top-width: 0;
  display: none;
}
.adaAutocompleteControlErrorMsg {
  margin: 0;
  border-color: #b21f13;
  color: #b21f13!important;
  border-style: solid;
  border-width: 1px;
  width: 100%!important;
  border-top-width: 0;
  display: none;
}
.adaAutocompleteStatus {
  border: 0px;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin-bottom: -1px;
  margin-right: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.adaAutocompleteError .adaAutocompleteErrorMsg,
.adaAutocompleteError .adaAutocompleteControlErrorMsg {
  display: block;
}
.adaAutocompleteLabel {
  position: absolute;
  top: 0.4rem;
  left: 0rem;
  transform: translate(14px, 17px) scale(1);
  font-size: 1.6rem;
  color: #5E7079;
}
.adaAutocompleteLabel.adaAutocompleteLabelShrink {
  transform: translate(-2px, 0) scale(0.75);
}
.adaAutocompleteError .adaAutocompleteLabel {
  color: #b21f13;
}
.autocomplete__hint,
.autocomplete__input {
	-webkit-appearance: none;
	appearance: none;
	border: 1px solid #c4c4c4;
	border-radius: 0;
	box-sizing: border-box;
	height: 2.5rem;
	line-height: 1.25;
	margin-bottom: 0;
	width: 100%;
}
.adaAutocompleteError .autocomplete__hint,
.adaAutocompleteError .autocomplete__input {
  border: 1px solid #b21f13;
}
.autocomplete__input {
	background-color: transparent;
	position: relative;
}
.autocomplete__hint {
	color: #505a5f;
	position: absolute;
}
.aaContainer[data-fetching="true"] .autocomplete__input--default {
  padding: 3.4rem 7rem 2.5rem 1.3rem;
}
.autocomplete__input--default {
  padding: 3.4rem 3.5rem 2.5rem 1.3rem;
}
.autocomplete__input--empty {
  padding: 2.8rem 1.3rem 3.1rem;
}
.autocomplete__input--focused {
  outline-style: solid;
  outline-width: 2px;
	outline-offset: -2px;
  outline-color: #001823;
}
.adaAutocompleteError .autocomplete__input--focused {
  outline-color: #b21f13;
}
.autocomplete__input--show-all-values {
	cursor: pointer;
	padding: 5px 35px 5px 5px;
}
.autocomplete__dropdown-arrow-down {
	display: inline-block;
	height: 24px;
	position: absolute;
	right: 8px;
	top: 10px;
	width: 24px;
	z-index: -1;
}
.autocomplete__menu {
	background-color: #fff;
	border: 1px solid #c4c4c4;
	border-top: 0;
	color: #001823;
	margin: 0;
	max-height: 342px;
	overflow-x: hidden;
	padding: 0;
	width: 100%;
	width: calc(100% - 4px);
}
.autocomplete__menu--visible {
	display: block;
}
.autocomplete__menu--hidden {
	display: none;
}
.autocomplete__menu--overlay {
	box-shadow: 0 2px 6px rgba(0, 0, 0, .257);
	left: 0;
	position: absolute;
	top: 100%;
	z-index: 100;
}
.autocomplete__menu--inline {
	position: relative;
}
.autocomplete__option {
  border-bottom: 1px solid #fff;
	border-left-width: 0;
	border-right-width: 0;
	border-top-width: 1px;
	cursor: pointer;
	display: block;
	position: relative;
  display: flex;
  box-sizing: border-box;
  min-height: 48px;
  align-items: center;
  justify-content: flex-start;
}
.autocomplete__option > * {
	pointer-events: none;
}
.autocomplete__option:first-of-type {
	border-top-width: 0;
}
.autocomplete__option:last-of-type {
	border-bottom-width: 0;
}
.autocomplete__option--focused,
.autocomplete__option:hover {
  background-color: rgba(0, 0, 0, 0.08);
	outline: none;
}
@media (-ms-high-contrast:active),
(forced-colors:active) {
	.autocomplete__menu {
		border-color: FieldText;
	}
	.autocomplete__option {
		background-color: Field;
		color: FieldText;
	}
	.autocomplete__option--focused,
	.autocomplete__option:hover {
		background-color: Highlight;
		background-color: SelectedItem;
		border-color: SelectedItem;
		color: HighlightText;
		color: SelectedItemText;
		forced-color-adjust: none;
		outline-color: SelectedItemText;
	}
}
.autocomplete__option--no-results {
	background-color: #fff;
	color: #5e7079;
	cursor: not-allowed;
}
.autocomplete__hint,
.autocomplete__input,
.autocomplete__option {
	font-size: 1.6rem;
	font-weight: 400;
}
.autocomplete__hint,
.autocomplete__option {
	padding: 5px;
}

// RADIO
.MuiRadio-root.Mui-checked {
  color: $darkBackground;
}
fieldset.radio-group-fieldset.hideRadio {
  margin-bottom: 0rem,
}
fieldset.radio-group-fieldset {
  margin: 0 0 2rem;
  border: 0;
  padding: 0;
  legend {
    margin-bottom: 1.6rem;
    padding: 0;
    h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 700;
    }
    h3, h4 {
      font-size: 1.6rem;
      font-weight: 500;
    }
    h5, h6 {
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
  .desc-radio-group {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
    padding: 0;
    &.note {
      color: $textHelpHintColor;
    }
  }
  .radio-group {
    margin: 0!important;
    &[data-error="true"] {
      .MuiFormGroup-row[role="radiogroup"] {
        margin-bottom: 0.5rem;
        .MuiFormControlLabel-root {
          border-color: $color-phoenix-red-d20;
          svg {
            fill: $radio-error-checked-icon-color;
          }
        }
      }
    }
    .Mui-error {
      width: fit-content;
    }
  }
  .MuiFormGroup-row[role="radiogroup"] {
    .MuiFormControlLabel-root {
      border-width: 1px;
      border-style: solid;
      border-color: $radio-default-border-color;
      border-radius: $btn-border-radius;
      background-color: $radio-default-backg-color;
      margin: 0 1.2rem 1.2rem 0;
      &:hover, &:focus {
        border-color: $radio-hover-border-color;
        .MuiIconButton-root svg {
          fill: $radio-hover-icon-color;
        }
      }
      .MuiIconButton-root svg {
        fill: $radio-default-icon-color;
      }
      .MuiFormControlLabel-label {
        padding-top: 0;
        padding-right: 1rem;
        color: $radio-default-label-color;
      }
    }
    &[data-radio-value="True"] {
      .MuiFormControlLabel-root[data-radio="Yes"] {
        border-color: $radio-checked-border-color;
        background-color: $radio-checked-backg-color;
        .MuiIconButton-root svg,
        .MuiButtonBase-root svg {
          fill: $radio-checked-icon-color;
        }
        .MuiFormControlLabel-label {
          font-weight: 700;
          color: $radio-checked-label-color;
        }
      }
    }
    &[data-radio-value="False"] {
      .MuiFormControlLabel-root[data-radio="No"] {
        border-color: $radio-checked-border-color; 
        background-color: $radio-checked-backg-color; 
        .MuiIconButton-root svg,
        .MuiButtonBase-root svg {
          fill: $radio-checked-icon-color;
        }
        .MuiFormControlLabel-label {
          font-weight: 700;
          color: $radio-checked-label-color;
        }
      }
    }
    // additional custom radio group
    &[data-radio-value="Mobile"] {
      .MuiFormControlLabel-root[data-radio="Mobile"] {
        border-color: $radio-checked-border-color; 
        background-color: $radio-checked-backg-color; 
        .MuiIconButton-root svg {
          fill: $radio-checked-icon-color;
        }
        .MuiFormControlLabel-label {
          font-weight: 700;
          color: $radio-checked-label-color;
        }
      }
    }
    &[data-radio-value="Home"] {
      .MuiFormControlLabel-root[data-radio="Home"] {
        border-color: $radio-checked-border-color; 
        background-color: $radio-checked-backg-color; 
        .MuiIconButton-root svg {
          fill: $radio-checked-icon-color;
        }
        .MuiFormControlLabel-label {
          font-weight: 700;
          color: $radio-checked-label-color;
        }
      }
    }
    &[data-radio-value="Work"] {
      .MuiFormControlLabel-root[data-radio="Work"] {
        border-color: $radio-checked-border-color; 
        background-color: $radio-checked-backg-color; 
        .MuiIconButton-root svg {
          fill: $radio-checked-icon-color;
        }
        .MuiFormControlLabel-label {
          font-weight: 700;
          color: $radio-checked-label-color;
        }
      }
    }
    .MuiTouchRipple-root {
      color: $color-white;
    }
  }
}

// CUSTOM RADIO
.MuiToggleButtonGroup-root {
  max-width: fit-content;
  min-width: fit-content;
  margin: 0 0 3rem;
  .MuiToggleButtonGroup-grouped {
    padding: 0.7rem 1rem 0.7rem 0.7rem;
    border-width: 1px;
    border-style: solid;
    border-color: $radio-default-border-color;
    border-radius: $btn-border-radius;
    background-color: $radio-default-backg-color;
    margin: 0 1rem 0 0;
    .MuiToggleButton-label {
      font-weight: 400;
      font-size: 1.6rem;
      color: $radio-default-label-color;
      text-transform: none;
      .MuiSvgIcon-root {
        margin-right: 1rem;
      }
    }
    &.Mui-selected {
      border-color: $radio-checked-border-color;
      background-color: $radio-checked-backg-color;
      .unchecked {
        display: none;
      }
      .checked {
        display: inline-block;
      }
      .icon svg {
        fill: $radio-checked-icon-color;
      }
      .MuiToggleButton-label {
        font-weight: 700;
        color: $radio-checked-label-color;
      }
    }
    &:not(.Mui-selected) {
      border-color: $radio-default-border-color;
      background-color: $radio-default-backg-color;
      .unchecked {
        display: inline-block;
        fill: $radio-default-icon-color;
      }
      .checked {
        display: none;
      }
      &:hover {
        border-color: $radio-hover-border-color;
        .unchecked {
          fill: $radio-hover-icon-color;
        }
      }
    }
  }
}

// CHECKBOX
.MuiFormControlLabel-root {
  display: flex;
  align-items: flex-start;
  .MuiCheckbox-root {
    padding-top: 1rem;
  }
  .MuiFormControlLabel-label {
    padding-top: 0.2rem;
    margin-top: 0.8rem;
  }
}
.MuiCheckbox-root {
  &.Mui-checked.Mui-disabled {
      color: $color-almost-black-l50;
  }
  &.Mui-checked {
    color: $darkBackground;
  }
  + .MuiFormControlLabel-label {
    font-size: 1.6rem;
  }
  // SVG icon
  &.Mui-disabled {
    .MuiIconButton-label .MuiSvgIcon-root  {
      width: 3rem;
      height: 3rem;
      fill: $color-almost-black-l50;
    }
  }
  .MuiIconButton-label .MuiSvgIcon-root  {
    fill: $darkBackground;
    width: 3rem;
    height: 3rem;
  }
}
fieldset label {
  .MuiFormControlLabel-label {
    margin-top: 0.8rem;
  }
}
.check-the-box {
  .check-the-box-legend {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: $checkbox-default-label-color;
    &.leadHeader {
      font-size: 2rem;
    }
  }
  .MuiFormControlLabel-root[error] .MuiSvgIcon-root {
    fill: $radio-error-checked-icon-color;
  }
  .MuiFormControlLabel-label {
    margin-top: 1.2rem;
  }
  p.MuiFormHelperText-root.Mui-error {
    width: fit-content;
  }
}
// CHECKBOX Group design
.checkbox-group {
  min-width: 25rem;
  max-width: 50rem;
  width: 100%;
  .MuiFormControlLabel-root {
    border-width: 1px;
    border-style: solid;
    border-color: $checkbox-default-border-color;
    border-radius: $checkbox-border-radius;
    background-color: $checkbox-default-backg-color;
    margin: 0 1.2rem 1.2rem 0;
    padding-right: 1rem;
    .MuiFormControlLabel-label {
      color: $checkbox-default-label-color;
      margin-bottom: 1.2rem;
    }
    &:hover, &:focus {
      border-color: $checkbox-hover-border-color;
      .MuiIconButton-root svg {
        fill: $checkbox-hover-icon-color;
      }
    }
    .MuiIconButton-root svg {
      fill: $checkbox-default-icon-color;
    }
  }
  // Add new data-checkbox names here
  [data-checkbox="currentCheckbox true"],
  [data-checkbox="previousCheckbox true"],
  [data-checkbox="workLikeCheckbox true"],
  [data-checkbox="noWorkCheckbox true"] {
    border-color: $checkbox-checked-border-color; 
    background-color: $checkbox-checked-backg-color;
    &:hover, &:focus {
      border-color: $checkbox-checked-border-color;
      .MuiIconButton-root svg {
        fill: $checkbox-checked-icon-color;
      }
    }
    .MuiIconButton-root svg {
      fill: $checkbox-checked-icon-color;
    }
    .MuiFormControlLabel-label {
      color: $checkbox-checked-label-color;
    }
  }
}

// TEXTFIELD
.MuiTextField-root {
  margin-bottom: 2rem;
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 7px) scale(0.75);
  }
  .MuiOutlinedInput-input {
    padding: 23.5px 14px 18.5px 14px;
  }
  .MuiInputBase-root {
    background-color: $color-white;
    border-radius: $input-border-radius;
    border-color: $form-control-theme;
    &.Mui-disabled {
      background-color: $color-almost-black-l90;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      top: 0;
    }
    fieldset legend {
      display: none;
    }
    &.Mui-focused fieldset {
      border-color: $form-control-theme;
      border-style: solid;
      border-width: 2px;
    }
  }
  label.Mui-focused.MuiInputLabel-root,
  label.MuiFormLabel-filled {
    color: $input-focus-label-color;
  }
  .MuiInputAdornment-root {
    &.MuiInputAdornment-positionStart {
      margin-right: 0;
      padding-top: 5px;
    }
  }
  // Invalid
  .Mui-error {
    &.MuiFormLabel-root {
      color: $error-smalltext-color;
    }
    &.MuiOutlinedInput-root {
      border-radius: $input-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &.Mui-focused fieldset {
        border-style: solid;
        border-color: $input-error-border-color;
      }
    }
    label.Mui-focused {
      color: $error-smalltext-color;
    }
    & + .MuiFormHelperText-contained {
      border-color: $input-error-border-color;
      color: $error-smalltext-color;
      border-style: solid;
      border-width: 1px;
      border-radius: $input-border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-width: inherit;
      margin: 0;
      padding: 0.4rem 0.8rem;
    }
    // Error design
    &.MuiFormHelperText-root {
      padding: 0.5rem 0.8rem 0.6rem 4rem;
      position: relative;
      background-color: $error-light-backg-color;
      &::before {
        content: url('modules/admissions/components/icons/error.svg');
        position: absolute;
        left: 1rem; 
        top: 0.6rem;
      }
    }
  }
}
// Radio group error - checkbox error
.MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  padding: 0.5rem 0.8rem 0.6rem 3rem;
  position: relative;
  background-color: $error-light-backg-color;
  &::before {
    content: url('modules/admissions/components/icons/error.svg');
    position: absolute;
    left: 1rem; 
    top: 0.6rem;
  }
  @media (min-width: $min-md) {
    &#checkboxButtunGroup-helper-text {
      width: 80%;
    }
  }
}
// Drop-down error
.Mui-error + .MuiFormHelperText-contained {
  padding: 0.5rem 0.8rem 0.6rem;
  padding-left: 3rem!important;
  position: relative;
  background-color: $error-light-backg-color;
  &::before {
    content: url('modules/admissions/components/icons/error.svg');
    position: absolute;
    left: 1rem; 
    top: 0.6rem;
  }
}
// review errors - custom error message
[class$="SectionErrorMessage"],
[class$="SectionErrorMessage jumpToError"],
.form-error-msg {
  padding-left: 3rem!important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  position: relative;
  width: fit-content;
  background-color: $error-light-backg-color;
  font-weight: 400!important;
  font-size: 1.4rem!important;
  line-height: 1.66;
  color: $alert-error-txt-color; //$error-smalltext-color;
  &::before {
    content: url('modules/admissions/components/icons/error.svg');
    position: absolute;
    left: 1rem; 
    top: 0.6rem;
  }
}
.form-error-msg-noicon {
  padding-left: 3rem!important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  position: relative;
  width: fit-content;
  background-color: $error-light-backg-color;
  font-weight: 400!important;
  font-size: 1.4rem!important;
  line-height: 1.66;
  color: $alert-error-txt-color; //$error-smalltext-color;
}
.block-msg {
  padding-left: 3rem!important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  position: relative;
  width: fit-content;
  font-weight: 400!important;
  font-size: 1.4rem!important;
  line-height: 1.66;
  &::before {
    position: absolute;
    left: 1rem; 
    top: 0.6rem;
  }
}
.block-msg-nobackground {
  padding-left: 3.5rem!important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-right: 1rem;
  position: relative;
  width: fit-content;
  font-weight: 700!important;
  font-size: 1.6rem!important;
  line-height: 1.66;
  &::before {
    position: absolute;
    left: 1rem; 
    top: 0.4rem;
  }
}
.info-msg {
  background-color: $info-light-backg-color;
  color: $alert-info-txt-color;
  &::before {
    content: url('modules/admissions/components/icons/info.svg');
  }
}
.info-msg-noicon {
  background-color: $info-light-backg-color;
  color: $alert-info-txt-color;
}
.warning-msg {
  background-color: $warning-light-backg-color;
  color: $alert-warning-txt-color;
  &::before {
    content: url('modules/admissions/components/icons/warning.svg');
  }
}
.warning-msg-noicon {
  background-color: $warning-light-backg-color;
  color: $alert-warning-txt-color;
  padding-left: 1rem!important;
  margin-top: 1rem;
}
.warning-msg-nobackground {
  color: $warning-backg-color;
  margin-left: 3rem;
  margin-top: 2rem;
  margin-bottom: -4rem;
  &::before {
    content: url('modules/admissions/components/icons/warning.svg');
  }
}
.compact-msg {
  @media (max-width: $max-sm) {
    padding-left: 0.6rem!important;
    &::before {
      content: none;
    }
  }
}
.disabled-padlock-icon {
  fill: $color-almost-black-l40;
}
.MuiInputLabel-root {
  &.MuiFormLabel-filled {
    color: $input-label-color;
  }
}

// SELECT
.Mui-disabled .MuiSelect-root.MuiSelect-select {
  background-color: transparent;
}

.MuiSelect-root.MuiSelect-select {
  background-color: $color-white;
}
.MuiPopover-root .MuiPaper-root {
  [role=listbox] {
    .MuiButtonBase-root {
      &:hover {
        color: $color-white;
        background-color: $form-control-theme;
      }
    }
  }
}
// NATIVE SELECT
[data-select=nativeSelect] {
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 7px) scale(0.75);
  }
  .MuiOutlinedInput-input {
    padding: 23.5px 14px 18.5px 14px;
  }
  & .MuiInputBase-root {
    background-color: $color-white;
    border-radius: $input-border-radius;
    border-color: $form-control-theme;
    & fieldset legend {
      display: none;
    }
    &.Mui-focused fieldset {
      border-color: $form-control-theme;
      border-style: solid;
      border-width: 2px;
    }
  }
  & label.Mui-focused.MuiInputLabel-root, & label.MuiFormLabel-filled {
    color: $adaGreyOnWhite;
  }
  // Invalid
  & .Mui-error {
    fieldset {
      border-radius: $input-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.MuiOutlinedInput-root {
      border-radius: $input-border-radius;
      &.Mui-focused fieldset {
        border-style: solid;
        border-color: $input-error-border-color;
      }
    }
    & label.Mui-focused {
      color: $error-smalltext-color;
    }
    & + .MuiFormHelperText-contained {
      border-color: $input-error-border-color;
      color: $error-smalltext-color;
      border-style: solid;
      border-width: 1px;
      border-top-width: inherit;
      margin: 0;
      border-radius: $input-border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    & .MuiFormHelperText-root {
      color: $input-error-border-color;
    }
  }
}

// ALERT snackbar
.alertWrapper {
  .MuiAlert-root {
    font-size: 1.6rem;
    line-height: 1.4;
  }
  .MuiAlert-outlinedWarning,
  .MuiAlert-outlinedSuccess,
  .MuiAlert-outlinedError,
  .MuiAlert-outlinedInfo {
    background-color: #fff;
  }
}
// Notistack Snackbar
[class*="SnackbarItem-variantWarning-"] {
  font-size: 1.6rem;
  line-height: 1.4;
  border: 2px solid $warning-backg-color;
  background-color: #fff;
  color: $textColor;
  .MuiSvgIcon-root {
    fill: $warning-backg-color;
  }
}
[class*="SnackbarItem-variantSuccess-"] {
  font-size: 1.6rem;
  line-height: 1.4;
  border: 2px solid $success-backg-color;
  background-color: #fff;
  color: $textColor;
  .MuiSvgIcon-root {
    fill: $success-backg-color;
  }
}

// ALERT lab
.MuiAlert-root.alert {
  font-size: 1.4rem;
  color: $textColor;
  line-height: 1.4;
  border-radius: 0;
  padding: 0.6rem 1.4rem;
  margin-bottom: 2rem;
  .MuiAlert-icon {
    margin-right: 8px;
    padding: 0.7rem 0;
    opacity: 1;
  }
  p, ul.unordered-list {
    font-size: 1.4rem;
  }
  &.MuiAlert-standardInfo {
    border-left: 3px solid $info-backg-color;
    background-color: $info-light-backg-color;
    .MuiAlert-icon,
    .MuiSvgIcon-colorPrimary {
      color: $info-backg-color;
    }
  }
  &.MuiAlert-standardSuccess {
    border-left: 3px solid $success-backg-color;
    background-color: $success-light-backg-color;
    .MuiAlert-icon,
    .MuiSvgIcon-colorPrimary {
      color: $success-backg-color;
    }
  }
  &.MuiAlert-standardError {
    border-left: 3px solid $error-backg-color;
    background-color: $error-light-backg-color;
    .MuiAlert-icon,
    .MuiSvgIcon-colorPrimary {
      color: $error-backg-color;
    }
  }
  &.MuiAlert-standardWarning {
    border-left: 3px solid $warning-backg-color;
    background-color: $warning-light-backg-color;
    .MuiAlert-icon,
    .MuiSvgIcon-colorPrimary {
      color: $warning-backg-color;
    }
  }
  @media (min-width: $min-md) {
    font-size: 1.6rem;
    line-height: 1.6;
    .MuiAlert-icon {
      padding: 1rem 0;
    }
    p, ul.unordered-list {
      font-size: 1.6rem;
    }
  }
  // form control alert - existing email alert
  &.fcAlert {
    &.MuiAlert-standardInfo {
      border: 2px solid $info-backg-color;
      border-left-width: 5px;
      background-color: $color-white;
      margin-bottom: 0;
      .MuiAlert-icon {
        color: $info-backg-color;
      }
    }
    &.MuiAlert-standardWarning {
      border: 2px solid $warning-backg-color;
      border-left-width: 5px;
      background-color: $color-white;
      margin-bottom: 0;
      .MuiAlert-icon {
        color: $warning-backg-color;
      }
    }
    &.MuiAlert-standardError {
      border: 2px solid $error-backg-color;
      border-left-width: 5px;
      background-color: $color-white;
      margin-bottom: 0;
      .MuiAlert-icon {
        color: $error-backg-color;
      }
    }
  }
  // form control alert - tooltip
  &.fcAlert.fcTooltip {
    &.MuiAlert-standardWarning {
      border: 1px solid $tooltip-warning-arrow-color;
      background-color: $tooltip-info-backg-color;
      color: $alert-warning-txt-color;
      font-size: 1.4rem;
      margin-bottom: 0;
      .MuiAlert-icon svg {
        color: $tooltip-warning-arrow-color;
      }
      .arrow {
        width: 1em;
        height: 0.71em;
        overflow: hidden;
        position: absolute;
        box-sizing: border-box;
        left: 50%;
        bottom: 0;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: -0.71em;
        font-size: 3rem;
      }
      .arrow::before {
        width: 100%;
        height: 100%;
        margin: auto;
        content: "";
        display: block;
        transform: rotate(45deg);
        background-color: $tooltip-warning-arrow-color;
        transform-origin: 100% 0;
      }
      @media (min-width: 1105px) { 
        .arrow {
          left: 0;
          top: 25%;
          width: 0.71em;
          height: 1em;
          margin-top: 4px;
          margin-left: -0.71em;
          margin-bottom: 4px;
        }
        .arrow::before {
          transform-origin: 100% 100%;
        }
      }
    }
  }
}
.fca {
  position: relative;
  max-width: 50rem;
  .fcAlert {
    position: absolute;
    z-index: 2;
    border-radius: $alert-border-radius;
    box-shadow: none;
    bottom: 1.5rem;
    @media (min-width: 1105px) {  
      bottom: -6.5rem;
      right: -51.4rem;
    }
    .MuiButton-text {
      color: $alert-warning-txt-color;
    }
  }
  > * {
    width: 100%;
  }
}
.FctIconGrid {
  flex: none;
  svg {
    font-size: 2.2rem;
  }
}
.FctMessageGrid {
  flex: 1 1 auto;
  padding-left: 0.8rem;
  font-size: 1.4rem;
}
// alert beneath email control
[data-container="email-wrapper"].MuiTextField-root {
  .Mui-error.MuiFormLabel-root, .MuiFormLabel-asterisk {
    color: $alert-warning-txt-color;
  }
  .MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $warning-backg-color;
    &:focus {
      border-color: $warning-backg-color;
    }
  }
  + .emailMessage {
    margin-top: -2.1rem;
    .MuiAlert-root {
      font-size: 1.2rem;
      background-color: $warning-light-backg-color!important;
      border-width: 1px!important;
      color: $alert-warning-txt-color;
      .MuiAlert-icon {
        padding: 0.5rem 0;
      }
      .MuiAlert-message {
        padding: 0.2rem 0;
        > div {
          line-height: 1.6;
          padding-bottom: 0.8rem;
        }
        > strong {
          display: inline-block;
          line-height: 2;
          padding-bottom: 0.8rem;
        }
      }
      a {
        white-space: nowrap;
        color: inherit;
        &:hover, &:focus {
          color: $alert-warning-txt-hover-color;
        }
      }
    }
  }
}

// TOOLTIP
.tooltip-btn {
  padding: 0;
  vertical-align: baseline;
  &.afterText {
    vertical-align: text-bottom;
  }
}
.headerWithTooltip {
  padding-right: 0;
  @media (min-width: $min-md) {
    padding-right: 0.5rem;
  }
}
.headerTooltip-btn {
  > .MuiIconButton-root {
    font-size: 2rem;
  }
}
.tooltipAfterText {
  > .MuiTypography-root {
    display: inline;
    margin-right: 0.5rem;
  }
}
.MuiTooltip-popper > .MuiTooltip-tooltip {
  font-size: 1.4rem!important;
  font-weight: 400!important;
  line-height: 1.6!important;
  > * {
    font-size: 1.4rem!important;
    font-weight: 400!important;
    line-height: 1.6!important;
  }
}
// FormControlTooltip
#FormControlTooltip-warning.MuiTooltip-popper {
  z-index: 1299!important;
}
.MuiAutocomplete-popper[role="presentation"] > .MuiAutocomplete-paper {
  margin-bottom: 0;
}
// tooltip width
[role="tooltip"]#governmentPhotoIdToolTip-text {
  .MuiTooltip-tooltip {
    width: 20rem;
  }
}

// ACCORDION
// ------------------------------
.mainAccordion {
  .MuiAccordionSummary-expandIcon {
    padding-right: 0;
    padding-left: 0;
  }
  .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 6rem;
    color: $primary-color;
  }
  .MuiAccordionSummary-root {
    background-color: $color-almost-black-l90;
  }
}
// Expand/Collapse all Control and Accordion
.expandCollapseAll {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  @media (min-width: $min-md) {
    flex-direction: row;
  }
  .gridFirst {
    padding-left: 0;
    flex-basis: 100%;
    flex-grow: 0;
    @media (min-width: $min-md) {
      flex-basis: 33.3333%;
    }
  }
  .gridLast {
    padding-right: 0;
    flex-basis: 100%;
    flex-grow: 0;
    @media (min-width: $min-md) {
      flex-basis: 66.6667%;
    }
  }
}
// Expand/Collapse - NO all Control and Accordion
.expandCollapseAll.fullwidthAccordion {
  @media (min-width: $min-md) {
    flex-direction: column;
  }
  .gridFirst {
    .expandAllHeader {
      padding-bottom: 2rem;
    }
    @media (min-width: $min-md) {
      flex-basis: 100%;
    }
  }
  .gridLast {
    @media (min-width: $min-md) {
      flex-basis: 100%;
    }
  }
}
// Expand/Collapse Accordion
.expandAllAccordion {
  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
    .MuiIconButton-root {
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiSvgIcon-root {
      color: $primary2-color;
    }
  }
  &.primary {
    .MuiButtonBase-root.MuiAccordionSummary-root .MuiSvgIcon-root {
      color: $primary2-color;
    }
  }
  &.secondary {
    .MuiButtonBase-root.MuiAccordionSummary-root .MuiSvgIcon-root {
      color: $color-phoenix-red;
    }
    .expandAllAccordionHeader {
      color: $textColor;
    }
    .Mui-expanded .expandAllAccordionHeader {
      color: $color-phoenix-red;
    }
  }
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0 0 2rem;
    // content
    .MuiTypography-root {
      font-size: 1.6rem;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .MuiAccordionSummary-content {
    margin: 1.6rem 0;
    .expandAllAccordionHeader {
      margin: 0;
    }
  }
  &.MuiAccordion-root:before {
    background-color: transparent;
  }
  border-bottom: 1px solid $separator;
}
// header
.expandAllAccordionHeader {
  font-size: 1.6rem;
  font-weight: 700;
  @media (min-width: $min-md) {
    font-size: 2rem;
  }
}
.Mui-expanded .expandAllAccordionHeader {
  color: $primary2-color;
}
// decision letter in dashboard
.admissionDecision {
  margin: 0;
  @media (min-width: $min-md) {
    margin: 4.5rem 0 0;
  }
  [id="admissionDecisionCardParagraph"] {
    a[target="_blank"]::after {
        content: url('components/icons/img/open-in-new-window-blue-16.png');
        bottom: -2px;
      }
  };
}
// Task accordion
ul.taskUnorderedList {
  margin: 0;
  list-style-type: none;
  padding-left: 0;
  li {
    padding: 0 0 2rem;
  }
  // builder.io task content
  [id*="-content"] {
    .MuiAccordionDetails-root > div > span {
      ol {
        padding-left: 2rem;
        padding-top: 0.8rem;
        padding-bottom: 1.6rem;
        > li {
          list-style-type: unset;
          &::marker {
            font-weight: 700;
          }
        }
      }
      ul > li::marker {
        font-size: 2.4rem;
      }
      a[target="_blank"] {
        margin-right: 2rem;
        position: relative;
        u {
          color: #30718D;
        }
      }
      a[target="_blank"]::after {
        content: url('components/icons/img/open-in-new-window-blue-16.png');
        position: absolute;
        bottom: -4px;
      }
    }
  }
}
.taskAccordionPanel {
  border-radius: $accordion-border-radius;
  margin: 0;
  > .MuiAccordionSummary-root {
    padding-left: 0;
    border-bottom: 0.1rem solid $accordion-border-color;
    min-height: fit-content;
    .headerText {
      border-left: 0.1rem solid $accordion-border-color;
    }
    &.Mui-expanded {
      min-height: fit-content;
      color: $color-white;
      background-color: $accordion-task-hd-open-color;
      border-top-left-radius: $accordion-bkgd-border-radius;
      border-top-right-radius: $accordion-bkgd-border-radius;
      .MuiIconButton-edgeEnd {
        svg {
          fill: $color-white;
        }
      }
      .headerText {
        .MuiTypography-root {
          font-weight: 700;
        }
      }
    }
    &:not(.Mui-expanded) {
      border-bottom: 0;
      .iconStatus > div {
        border-bottom-left-radius: $accordion-bkgd-border-radius;
      }
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .iconStatus {
    > div {
      border-top-left-radius: $accordion-bkgd-border-radius;
      height: 100%;
    }
    .MuiSvgIcon-root {
      width: 6rem;
    }
  }
  .MuiAccordionDetails-root {
    padding: 1.5rem 1rem 2rem;
    @media (min-width: $min-md) {
      padding: 2rem;
    }
  }
  &[data-task-completed=false] {
    .iconStatus > div {
      background-color: $accordion-task-icon-bkgd-uncompleted-color;
      .MuiSvgIcon-root {
        fill: $accordion-task-uncompleted;
      }
    }
  }
  &[data-task-completed=true] {
    .iconStatus > div {
      background-color: $accordion-task-icon-bkgd-completed-color;
      .MuiSvgIcon-root {
        fill: $accordion-task-completed;
      }
    }
  }
}
// Footnotes [1], [2], etc... (asterisk)
// -------------------------------------
#root {
  counter-reset: footnotes;
}
[aria-describedby="footnote-label"]::after {
  content: '[' counter(footnotes) ']';
}
// linear progress
.MuiLinearProgress-root {
  background-color: $info_backg-color-static;
}
.MuiLinearProgress-barColorPrimary {
  background-color: $info-backg-color;
}

// TAB
// ------------------------------
.MuiTab-textColorSecondary {
  color:  #30718D;
  &.Mui-selected {
    color:  white;
    background-color: #1A3D4D;
  }
}

// EYEBROW TEXT
// ------------------------------
h1, h2, h3, h4, h5, div, p, span {
  &.eyebrowText {
    font-size: 1.2rem!important;
    font-weight: 700!important;
    color: $eyebrow-color!important;
    line-height: 1.6!important;
    text-transform: uppercase!important;
    letter-spacing: 0.2rem!important;
    padding: 0 0 1.4rem 0!important;
    margin: 0!important;
    @media (min-width: $min-md) {
      font-size: 1.6rem!important;
    }
  }
}
// CTA with Icon Card
.compactCard {
  border-color: $card-muted-border-color;
  padding: 1.6rem 1.2rem 1.4rem;
  .ctaHeaderGrid {
    align-items: flex-start;
  }
  .MuiGrid-root:first-child {
    padding: 0 1rem 0 0;
  }
  .MuiGrid-root:last-child {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    h3 {
      margin-bottom: 0;
    }
  }
  h3, .ctaParagraph {
    font-size: 1.6rem;
    @media (min-width: $min-lg) {
      font-size: 1.6rem;
    }
  }
  .ctaParagraph {
    padding-bottom: 0;
  }
}
.iconProminedCard {
  padding: 2.4rem;
  .ctaParagraph {
    font-size: 1.6rem;
  }
  @media (min-width: $min-lg) {
    .MuiGrid-item:first-child {
      padding-bottom: 0;
    }
    .MuiGrid-item:last-child {
      padding-top: 0;
    }
  }
  h3 {
    font-size: 2rem;
    @media (min-width: $min-lg) {
      font-size: 2.4rem!important;
    }
  }
}
.newWindowLinkInParagraph .linkWithIcon {
  justify-content: unset;
  margin-bottom: 0;
  display: inline;
  .MuiGrid-item {
    display: inline;
  }
}
// CTA with Image Card
.ctaWithImageCard {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas:
    "area1"
    "area2"
    "area3";
  @media (min-width: $min-md) {
    grid-template-columns: 55% 45%;
    grid-template-areas:
      "area1 area2"
      "area3 area2";
  }
}
.ctaWithImageCardArea1 {
  grid-area: area1;
}
.ctaWithImageCardArea2 {
  grid-area: area2;
}
.ctaWithImageCardArea3 {
  grid-area: area3;
}
// BLADE primary
// ------------------------------
.primaryBlade {
  margin-bottom: 5rem;
  padding: 3.5rem 1.2rem;
  background-color: $bladeBkg;
  text-align: center;
  @media (min-width: $min-md) {
    padding: 3.5rem 5rem;
  }
  .bladeHeader {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 2rem;
    @media (min-width: $min-md) {
      font-size: 2.4rem;
    }
  }
  .bladeIntro {
    font-size: 1.6rem;
    font-weight: 300;
    padding-bottom: 2rem;
    @media (min-width: $min-md) {
      font-size: 2rem;
    }
  }
  .verifyIdentityCtaInfo {
    color: #5E7079;
    padding-bottom: 2rem;
  }
  &.IDverificationBlade {
    margin-top: 5rem;
  }
}

// Dashboard
&[data-theme="dashboardTheme"] {
  background-color: transparent;
  .headerTopSpace {
    padding-top: 1.75rem;
    @media (min-width: $min-lg) {
      padding-top: 2.5rem;
    }
  }
  .mainHeader {
    background-color: transparent;
    box-shadow: none;
  }
  .topHeaderWrapper {
    background-color: transparent;
    padding: 1rem 2rem 0.75rem;
    .topHeader {
      display: none;
      @media (min-width: $min-lg) {
        display: block;
      }
    }
  }
  .topHeader {
    color: $textColor;
    padding-right: 1rem;
    > div {
      align-items: center;
    }
    .callHeaderText {
      display: none;
    }
    a {
      color: inherit;
      text-decoration: none;
      padding: 0.5rem 1.4rem;
      background-color: $color-white;
      border-radius: $header-top-links-radius;
      box-shadow: 1px 1px 2px rgba(0,0,0,.2);
    }
  }
  .questionCall {
    > div {
      align-items: center;
    }
    .hdPhoneIcon {
      display: block;
    }
  }
  .bottomHeaderWrapper {
    @media (min-width: $min-xl) {
      max-width: 142rem;
    }
    .topHeader {
      display: none;
      @media (max-width: $max-md) {
        display: block;
        margin: 0;
        a {
          padding: 0.5rem;
          border-radius: $section-border-radius;
          &.logoutLink {
            white-space: nowrap;
            line-height: 1.55;
            padding: 0.5rem 1rem;
          }
        }
        .hdPhoneNumber {
          display: none;
        }
      }
    }
  }
  .bottomHeader {
    margin: 0 1rem;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    border-radius: 2.4rem;
    background-color: $color-white;
    box-shadow: 1px 3px 3px rgba(0,0,0,.2);
    > svg {
      height: 4.8rem;
    }
    @media (min-width: $min-lg) {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      height: auto;
      border-radius: 3rem;
      > svg {
        height: 4.6rem;
      }
    }
    .welcomeUser {
      @media (max-width: $max-md) {
        display: none;
      }
    }
  }
  .hdLogoIcon {
    padding-top: 0;
    height: auto;
    width: 15rem;
    @media (min-width: $min-lg) {
      width: 18.2rem;
    }
  }
  .welcomeUser {
    font-size: 1.4rem;
    margin-right: 2rem;
    @media (min-width: $min-lg) {
      font-size: 1.6rem;
      margin-right: 4rem;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .welcomeIntro {
      padding-right: 0.5rem;
    }
    .userName {
      display: inline;
      font-size: 1.4rem;
      line-height: unset;
      @media (min-width: $min-lg) {
        display: inline;
        font-size: 1.6rem;
      }
    }
  }
  .headerAttachment {
    display: block;
    margin: 0 1rem;
    padding: 3.5rem 2rem 1rem;
    position: relative;
    top: 3rem;
    background-color: $backgroundLightBlue;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    @media (min-width: $min-lg) {
      display: none;
    }
    .welcomeUser {
      justify-content: end;
      margin-right: 0;
    }
  }
  // which logo
  .originalHDlogo {
    display: none;
  }
  .dashboardMobileHDlogo, .dashboardDesktopHDlogo {
    display: block;
  }
  .mainContainer {
    margin-top: 4rem;
    margin-bottom: 5rem;
    padding: 1rem 1rem 0;
    &#pgPersonalInfo {
      margin-top: 4rem;
      padding: 0 1rem 0;
      main {
        border-radius: 0;
        background-color: transparent;
      }
    }
    &#pgLogout {
      margin-top: 3.5rem;
      padding: 0 1rem 0;
      main {
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
      }
      .logoutSpinner {
        margin-top: 7rem;
        @media (min-width: $min-md) {
          margin-top: -1.5rem;
        }
        .spinnerContainer {
          padding-bottom: 7rem;
        }
      }
      .logoutTopSection {
        .topSectionCol1 {
          background-color: $color-white;
          border-radius: $section-border-radius;
          padding: 1.5rem 1.5rem 2.5rem;
          margin: 0 1.5rem 5rem;
          @media (min-width: $min-md) {
            padding: 3rem;
            margin-right: -2rem;
          }
        }
        .backgroundIcon {
          img {
            margin-top: -5rem;
            margin-bottom: -10rem;
            @media (max-width: $max-sm) {
              width: fill-available;
            }
          }
          @media (min-width: $min-md) {
            img {
              margin-top: -5rem;
              width: fill-available;
            }
          }
          @media (min-width: $min-lg) {
            img {
              margin-top: -15rem;
              height: 48rem;
              width: auto;
              margin-bottom: -7rem;
            }
          }
        }
        @media screen and (min-width: $min-md) and (max-width: $max-md) {
          align-items: center;
          .backgroundIcon {
            img {
              margin-bottom: auto;
            }
          }
        }
      }
      .logoutSection {
        background-color: $color-white;
        border-radius: $section-border-radius;
        padding: 1.5rem 1.5rem 2.5rem;
        margin-bottom: 3.5rem;
        // Log in section
        .topSectionCol1 {
          margin-bottom: 0;
        }
        // before you continue
        h2.h2Header {
          padding-top: 0.8rem;
        }
        // Need help
        .wereHereToHelp {
          margin: 0;
          h2 {
            padding-top: 0.8rem;
          }
          .phoneHelp {
            margin-top: 1rem;
          }
        }
        @media (min-width: $min-md) {
          padding: 3rem;
        }
      }
      @media (max-width: $max-sm) {
        .backgroundIcon {
          img {
            margin-top: -1.5rem;
          }
        }
      }
      .paperLayout {
        @media (min-width: 1340px) {
          margin-left: 7%;
          width: 86%;
        }
      }
    }
    @media (min-width: $min-md) {
      margin-bottom: 10rem;
      &#pgPersonalInfo {
        main {
          padding: 0;
        }
      }
      &#pgLogout {
        margin-top: 12rem;
        padding: 0 1rem 0;
        main {
          padding: 0;
        }
      }
    }
    @media (min-width: $min-lg) {
      margin: 12.5rem auto 10rem;
      padding: 2rem 1rem;
      &#pgPersonalInfo {
        margin-top: 4rem;
        padding: 0 2rem 2rem;
      }
      &#pgLogout {
        margin-top: 12.2rem;
        padding: 0 1rem 2rem;
      }
    }
    @media (min-width: $min-xl) {
      &#pgLogout {
        width: 144rem;
      }
    }
  }
  // main
  main {
    background-color: $color-white;
    border-radius: 2rem;
    padding: 0 1.5rem;
    &.personalInfoContainer {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: $min-md) {
      padding: 0 1rem;
    }
    // Main stepper
    > .main-nav {
      padding-top: 2rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      @media (min-width: $min-md) {
        margin-left: -1rem;
        margin-right: -1rem;
      }
      > .MuiStepper-horizontal {
        padding: 1.5rem 2rem 3rem;
        @media (min-width: $min-lg) {
          padding: 1.4rem 5.4rem;
          border: 0;
        }
      }
      // Tablet/Desktop
      @media (min-width: $min-md) {}
      // Desktop
      @media (min-width: $min-lg) {
        padding-top: 4rem;
      }
    }
    // Secondary stepper
    .sub-nav {
      margin: 0 -1.5rem;
      > .MuiStepper-horizontal {
        width: 100%;
        padding: 1.2rem 0;
      }
    }
  }
  // personal info
  .mainContainer#pgPersonalInfo {
    .main-nav {
      background-color: $color-white;
      border-radius: 2rem;
      margin-left: 0;
      margin-right: 0;
      padding-top: 1.25rem;
      @media (min-width: $min-lg) {
        padding-top: 0;
        padding-bottom: 0;
      }
      > .MuiStepper-horizontal {
        background-color: unset;
        border: 0;
        padding-left: 1rem;
      }
    }
    // form
    .gridOneWithBackground {
      padding: 5rem 4rem 2.5rem;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      @media (min-width: $min-lg) {
        padding: 5rem 2rem 2.5rem 3rem;
      }
      @media (min-width: 1200px) {
        padding: 5rem 5rem 2.5rem 6rem;
      }
    }
    .gridTwoWithStepper {
      padding: 3.5rem 0 0;
      @media (min-width: $min-lg) {
        padding-left: 3.5rem;
        padding-right: 0;
      }
    }
    .formPersonalInfo {
      @media (min-width: $min-lg) {
        padding-left: 0;
        padding-right: 0;
      }
      .emailSection,
      .legalNameSection,
      .mailingAddressSection,
      .phoneNumberSection,
      .accountCreationSection {
        background-color: $color-white;
        border-radius: 2rem;
        padding: 2rem 1.5rem;
        margin-bottom: 2rem;
        @media (min-width: $min-lg) {
          padding: 2rem 4rem;
        }
        @media (min-width: 1200px) {
          padding: 2rem 4rem 2rem 6.5rem;
        }
      }
      .haveAnAccountSection {
        margin-bottom: 0;
      }
      .mailingAddressSection > h2, .phoneNumberSection > h2 {
        margin-top: 0;
      }
    }
    #addressHeading, #phoneHeading {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    fieldset.radio-group-fieldset {
      legend h2 {
        font-size: 1.6rem;
        font-weight: 500;
      }
      p { // On clean-up implement 'descriptionNote' prop
        color: $textHelpHintColor;
      }
    }
    #phoneType-radio-group {
      legend {
        font-size: 1.6rem;
        font-weight: 500;
      }
      #phoneType-input {
        > label {
          margin: 0 1rem 1.2rem 0;
        }
      }
    }
  }
  // StandAlonePage
  &.stand-alone-page {
    .pgContainer {
      @media (min-width: $min-lg) {
        margin: 14.5rem 1.5rem 10rem;
      }
      @media (min-width: $min-xl) {
        margin: 14.5rem auto 10rem;
        width: 142rem;
      }
      > .backToDashWrap {
        margin-top: 6rem;
        margin-left: 3rem;
        @media (min-width: $min-lg) {
          margin-top: 14.5rem;
          margin-left: 3rem;
        }
      }
      // DO NOT CONSOLIDATE CODE
      > .backToDashWrap + main#pgUpload {
        @media (min-width: $min-lg) {
          margin: 0rem auto 10rem;
        }
      }
      // KEEP CODE in this order
      > main#pgUpload {
        @media (max-width: $max-md) {
          margin-top: 5rem!important;
        }
      }
      // DO NOT CONSOLIDATE CODE
      > .backToDashWrap + main#pgUpload {
        @media (max-width: $max-md) {
          margin-top: 2rem!important;
        }
      }
      main#pgUpload, main#pgTaskSign {
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin: 0 1rem 5rem;
        @media (min-width: $min-lg) {
          margin: 0 1.5rem 0;
        }
      }
      main#pgUpload {
        // table
        .uploadTableWrapper {
          .MuiTabPanel-root:last-child {
            @media (max-width: $max-sm) {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
      #accentDashboardApplication {
        line-height: 1;
      }
    }
  }
  // footer
  footer[role="contentinfo"] {
    background-color: $color-dark-river-blue;
  }
  // Review page
  #pgReview {
    .reviewSectionField {
      p#email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    @media (max-width: $max-sm) {
      .MuiExpansionPanel-root .MuiExpansionPanelDetails-root {
        padding: 1.6rem;
      }
    }
  }
  // Transition page
  #pgTransition {
    margin-top: -17rem;
    margin-bottom: -9rem;
    padding: 0;
    @media (min-width: $min-md) {
      margin-top: -5rem;
      margin-bottom: 5rem;
    }
    @media (min-width: $min-lg) {
      margin-top: -0.5rem;
    }
    .taskPrimaryGrid, .taskSecondaryGrid {
      padding-top: 0;
    }
    .noTaskPrimaryGrid, .noTaskSecondaryGrid {
      padding-top: 0;
    }
    .pgWrapperGrid {
      @media (min-width: $min-md) {
        padding: 1rem 0;
      }
    }
    .page-wrapper > div {
      @media (max-width: $max-sm) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    background-color: unset;
  }
  // dialogs
  > .MuiDialog-root {
    .MuiPaper-root {
      @media (max-width: $max-sm) {
        margin-right: 1rem;
        margin-left: 1rem;
        height: auto;
      }
    }
    > .MuiDialog-container.MuiDialog-scrollBody {
      @media (max-width: $max-sm) {
        .MuiPaper-root.MuiDialog-paper {
          max-width: 100%;
        }
      }
    }
    > .MuiDialog-container.MuiDialog-scrollPaper  {
      @media (max-width: $max-sm) {
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        > .MuiPaper-root {
          max-width: 100%;
        }
      }
    }
    // Review acknowledgements/sign submit
    &.ReviewAllForms {
      #docToDisplay {
        button.printBtn {
          box-shadow: none;
          text-transform: inherit;
          font-weight: $font-weight-medium;
          padding: 0.6rem 1.6rem;
          border-width: $btn-border-width;
          border-style: $btn-border-style;
          border-radius: $btn-border-radius;
          color: $color-white;
          border-color: transparent;
          background-color: $btn-primary2-bg;
          &:hover {
            color: $color-white;
            border-color: transparent;
            background-color: $primary2-hover-color;
            border-width: $btn-border-width;
            .MuiSvgIcon-root {
              fill: $primary-hover-color;
            }
          }
          &:active {
            color: $color-white;
            border-color: transparent;
            background-color: $primary2-active-color;
          }
          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
      @media (max-width: $max-sm) {
        .MuiDialog-scrollPaper .MuiDialog-paper {
          margin-right: 0;
          margin-left: 0;
          header {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
  // Print button
  #mySubmittedApplication, #identityPolicy {
    .MuiDialogContent-root {
      padding: 1.5rem 4rem 4.8rem!important;
      @media (max-width: $max-sm) {
        padding: 1.5rem 0.8rem 4.8rem!important;
      }
    }
    .Print_Button {
      display: none;
    }
    .printBtnWrapper {
      height: 3.5rem;
    }
    button.printBtn {
      box-shadow: none;
      text-transform: inherit;
      font-weight: $font-weight-medium;
      margin-bottom: 1rem;
      padding: 0.6rem 1.6rem;
      border-width: $btn-border-width;
      border-style: $btn-border-style;
      border-radius: $btn-border-radius;
      color: $color-white;
      border-color: transparent;
      background-color: $btn-primary2-bg;
      &:hover {
        color: $color-white;
        border-color: transparent;
        background-color: $primary2-hover-color;
        border-width: $btn-border-width;
        .MuiSvgIcon-root {
          fill: $primary-hover-color;
        }
      }
      &:active {
        color: $color-white;
        border-color: transparent;
        background-color: $primary2-active-color;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
#pgDashboard {
  // Dashboard page
  #accentDashboard {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 2.4rem;
    @media (min-width: $min-md) {
      font-size: 5.6rem;
      line-height: 0.65;
    }
  }
  .introductionText {
    max-width: 63rem;
    font-size: 1.6;
    @media (min-width: $min-md) {
      font-size: 2rem;
    }
  }
  .taskPrimaryGrid {
    .pageSection {
      @media (max-width: $max-md) {
        margin-bottom: 0.5rem;
      }
    }
  }
  .page-wrapper {
    padding-top: 0;
  }
  .pgWrapperGrid {
    @media (min-width: $min-md) {
      padding: 0;
    }
  }
  .newThemeTabs {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    // sticky tabs
    .MuiTabs-root {
      position: sticky;
      top: 6.5rem;
      z-index: 1099;
      button[aria-selected="false"] {
        background-color: $color-blue;
      }
      .MuiTabs-indicator {
        background-color: $tabsIndicatorColor2;
      }
    }
    .MuiTabPanel-root {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0;
      @media (min-width: $min-md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
  .helpContainer {
    @media (max-width: $max-sm) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
  }
}
.pageSection {
  background-color: #fff;
  border-radius: 2rem;
  margin-bottom: 3.5rem;
  padding: 2rem 1.5rem;
  @media (min-width: $min-md) {
    padding: 3rem 4rem;
  }
}
.backToDashWrap {
  margin-bottom: 2rem;
  @media (min-width: $min-md) {
    margin-bottom: 4rem;
  }
}
.backToDashboard {
  margin-bottom: 1.6rem;
  a.MuiButton-root.MuiButton-text {
    padding-top: 0.6rem;
    @media (min-width: $min-md) {
      padding-top: 0;
    }
  }
}
#accentDashboardApplication {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 0.75;
  margin-bottom: 2.4rem;
  @media (min-width: $min-md) {
    font-size: 4rem;
  }
}
.responsive-image {
  width: 100%;
  height: auto;
}